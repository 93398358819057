<template>
  <div class="text-center text-warning my-3">
    <b-spinner class="align-middle"></b-spinner>
    <br />
    <strong>D&eacute;connexion en cours, veuillez patienter...</strong>
  </div>
</template>

<script>
export default {
  name: "LogoutView",
  async created() {
    await this.$store.dispatch("auth/logout");
    this.$router.push({ name: "login" });
  },
};
</script>
